'use client';

import React from 'react';

import { Button } from '@/app/_components/ui/button';

export default function GlobalError({ reset }: { reset: () => void }) {
  return (
    <html lang="en">
      <body>
        <main className="flex h-full flex-col items-center justify-center gap-2">
          <h2>Something went wrong</h2>
          <Button onClick={() => reset()}>Try again</Button>
        </main>
      </body>
    </html>
  );
}
